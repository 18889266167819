/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
require('es6-promise/auto');

window.Vue = require('vue');

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('user-edit', require('./components/UserEdit.vue').default);
Vue.component('role-edit', require('./components/RoleEdit.vue').default);
Vue.component('photo-edit', require('./components/PhotoEdit.vue').default);
Vue.component('spot-edit', require('./components/SpotEdit.vue').default);
Vue.component('event-edit', require('./components/EventEdit.vue').default);
Vue.component('plan-edit', require('./components/PlanEdit.vue').default);
Vue.component('article-edit', require('./components/ArticleEdit.vue').default);
Vue.component('special-edit', require('./components/SpecialEdit.vue').default);
Vue.component('event-status', require('./components/view_element/EventStatus.vue').default);
Vue.component('creator-registration', require('./components/CreatorRegistration.vue').default);
Vue.component('recommend-button', require('./components/standalone_element/RecommendButton.vue').default);
Vue.component('recommends', require('./components/Recommends.vue').default);

// Vue.directive('click-outside', {
//   bind: function (el, binding, vnode) {
//     el.clickOutsideEvent = function (event) {
//       // here I check that click was outside the el and his childrens
//       if (!(el == event.target || el.contains(event.target))) {
//         // and if it did, call method provided in attribute value
//         vnode.context[binding.expression](event);
//       }
//     };
//     document.body.addEventListener('click', el.clickOutsideEvent)
//   },
//   unbind: function (el) {
//     document.body.removeEventListener('click', el.clickOutsideEvent)
//   },
// });

// Vue.directive('click-outside', {
//   bind: function (el, binding, vnode) {
//     console.log(el)
//     this.event = function (event) {
//     if (!(el == event.target || el.contains(event.target) || event.target.className === "select2-selection__choice__remove" || event.target.className === "select2-search__field" )) {
//         vnode.context[binding.expression](event);
//       }
//     };
//     document.body.addEventListener('click', this.event)
//   },
//   unbind: function (el) {
//     document.body.removeEventListener('click', this.event)
//   },
// });

// Vue.directive('closable', {
//   bind (el, binding, vnode) {
//     // Here's the click/touchstart handler
//     // (it is registered below)
//     handleOutsideClick = (e) => {
//       e.stopPropagation()
//       // Get the handler method name and the exclude array
//       // from the object used in v-closable
//       const { handler, exclude } = binding.value

//       // This variable indicates if the clicked element is excluded
//       let clickedOnExcludedEl = false
//       exclude.forEach(refName => {
//         // We only run this code if we haven't detected
//         // any excluded element yet
//         if (!clickedOnExcludedEl) {
//           // Get the element using the reference name
//           const excludedEl = vnode.context.$refs[refName]
//           // See if this excluded element
//           // is the same element the user just clicked on
//           clickedOnExcludedEl = excludedEl.contains(e.target)
//         }
//       })

//       // We check to see if the clicked element is not
//       // the dialog element and not excluded
//       if (!el.contains(e.target) && !clickedOnExcludedEl) {
//         // If the clicked element is outside the dialog
//         // and not the button, then call the outside-click handler
//         // from the same component this directive is used in
//         vnode.context[handler]()
//       }
//     }
//     // Register click/touchstart event listeners on the whole page
//     document.addEventListener('click', handleOutsideClick)
//     document.addEventListener('touchstart', handleOutsideClick)
//   },

//   unbind () {
//     // If the element that has v-closable is removed, then
//     // unbind click/touchstart listeners from the whole page
//     document.removeEventListener('click', handleOutsideClick)
//     document.removeEventListener('touchstart', handleOutsideClick)
//   }
// })

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#kd',
});
