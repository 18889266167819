<docs>
参考
https://www.youtube.com/watch?v=YmyAEyGL33U
※↑これはmultiple対応
</docs>

<template>
    <div class="imageFieldComponent">
        <!-- <div :class="name + '_edit'">
          <span class="btn" v-show="(file_preview_src || currentFile) && !nowChangeFlag" v-on:click="doChange()">{{ title }}を変更</span>
          <span class="btn" v-show="(file_preview_src || currentFile) && nowChangeFlag" v-on:click="cancelChange()">{{ title }}変更をキャンセル</span>
          <span class="btn" v-show="(file_preview_src || currentFile) && !nowChangeFlag" v-on:click="remove()">{{ title }}を削除</span>
        </div> -->
        <div class="">
          <div class="inner">
            <div class="dialog-content uploader" v-show="uploaderShowFlag"
            @dragenter="onDragEnter"
            @dragleave="onDragLeave"
            @dragover.prevent
            @drop="onDrop"
            :class="{ dragging: isDragging }">
              <div class="inner" v-if="!nowRenderingFlag">
                <p class="message">ここにファイルをドロップ<span>または</span></p>
                <div class="file-input">
                  <label :for="name + '_input_field'" class="btn btn-primary">ファイルを選択</label>
                  <input type="file" :id="name + '_input_field'" @change="onInputChange">
                </div>
              </div>
              <div class="inner" v-if="nowRenderingFlag">
                <div><span class="loader icon">Loading...</span></div>
                <p class="message">プレビュー生成中。<br>しばらくお待ちください…</p>
              </div>
            </div>
            <image-preview v-show="previewFile && !uploaderShowFlag"
            :previewFile="previewFile"
            :isShow="isShow"
            @setImageRotate="setImageRotate"
            @remove="remove"
            >
            </image-preview>
          </div>
        </div>
    </div>
</template>

<script>
    import ImagePreview from './ImagePreview'
    import loadImage from 'blueimp-load-image'
    export default {
        components: {
          ImagePreview,
        },
        // フィールド名
        props: {
            // 日本語のフィールド名
            title: {
                type: String,
                default: null,
                required: false,
            },
            // フィールドのname
            name: {
                type: String,
                required: true,
            },
            // 現在の画像
            current: {
                type: String,
                default: null,
                required: false,
            },
            // プレビューパス
            prvpath: {
                type: String,
                default: null,
                required: false,
            },
            // 表示されているか
            isShow: {
                type: Boolean,
                default: null,
                required: false,
            },
        },
        data: () => ({
            // アップローダー表示フラグ
            uploaderShowFlag: true,
            // ファイルを指定した時に描画が完了するまではTrueになる
            nowRenderingFlag: false,
            // // 画像を変更中
            // nowChangeFlag: false,
            // ドラッグ中かどうか
            isDragging: false,
            // ドラッグ中かどうかの判定用
            dragCount: 0,
            // すでに登録済みの画像
            currentFile: null,
            // すでに登録済みの画像を消す時に入れるゴミ箱（現在使ってない）
            currentFileRemoved: null,

            previewFile: {},
        }),
        created() {
            // もともとファイルが登録されている場合は変数に入れておく
            if(this.current) {
              this.currentFile = {
                // 現在の画像のファイル名
                name: this.current,
                // 現在の画像のパス。正式なパスに書き換える。
                path: this.prvpath + this.current,
              }
              this.previewFile = {
                src: this.prvpath + this.current,
              }
              // アップローダーを非表示
              this.uploaderShowFlag = false;
            }
        },
        watch: {
            current(newImg, oldImg) {
              if(this.current) {
                  this.currentFile = {
                    // 現在の画像のファイル名
                    name: this.current,
                    // 現在の画像のパス。正式なパスに書き換える。
                    path: this.prvpath + this.current,
                  }
                  this.previewFile = {
                    src: this.prvpath + this.current,
                  }
              } else {
                // アップローダーを表示
                this.uploaderShowFlag = true;
              }
            }
        },
        methods: {
            setImageRotate(imageRotate) {
              // console.log(imageRotate);
              // this.imageRotate = imageRotate;
              this.$emit('setImageRotate', imageRotate);
            },
            // アップローダーを表示
            uploaderShow() {
              this.uploaderShowFlag = true;
            },
            // // 変更ボタンを押したとき
            // doChange() {
            //   // 変更中フラグtrue
            //   this.nowChangeFlag = true;
            //   // アップローダー表示
            //   this.uploaderShowFlag = true;
            // },
            // // 変更キャンセルボタンを押したとき
            // cancelChange() {
            //   // 変更中フラグfalse
            //   this.nowChangeFlag = false;
            //   // アップローダーを非表示
            //   this.uploaderShowFlag = false;
            // },
            // ドラッグがdrop領域に入った時
            onDragEnter(e) {
              e.preventDefault();
              this.dragCount++;
              this.isDragging = true;
            },
            // ドラッグがdrop領域を出た時
            onDragLeave(e) {
              e.preventDefault();
              this.dragCount--;
              // console.log(this.dragCount);
              if(this.dragCount <= 0) {
                this.isDragging = false;
              }
            },
            // ファイルフィールドのchangeイベント
            onInputChange(e) {
              const file = e.target.files[0];
              // console.log('change');
              this.checkExif(file);
              this.addImage(file);
            },
            // drop領域内でdropした時
            onDrop(e) {
              e.preventDefault(); // デフォルトの動作（この場合はファイルをブラウザで開く）をキャンセル
              e.stopPropagation(); // 現在のイベントの親要素への伝播（propagation）止める
              this.dragCount = 0;
              this.isDragging = false;
              // ドロップされたファイルを定数に入れる（この場合はマルチプルではないので最初の一個だけ）
              const file = e.dataTransfer.files[0];
              // 下の関数内の処理に進む
              this.addImage(file);
            },

            checkExif(file) {
              // console.log('check');
              loadImage.parseMetaData(file, function (data) {
                // console.log("Exif data: ", data.exif);
                const exif = data.exif.get("Exif");　// 2. Exif IFDの読み出し
                const dateTimeOriginal = exif.get("DateTimeOriginal");
                // console.log(dateTimeOriginal);
                this.$emit('setDateTime', dateTimeOriginal);
              }.bind(this), 100);
            },

            // ファイルのデータを準備したりプレビューを作ったりする
            addImage(file) {
              this.$emit('setProcessing',true);
              // console.log('upload');
              // this.checkExif(file);
              // ドロップされたものが画像かどうかチェック
              if(!file.type.match('image.[jpeg|png|gif]')) {
                alert(`${file.name} は非対応のファイル形式です`);
                this.$emit('setProcessing',false);
                return;
              }
              // ファイルサイズをチェック
              if(file.size > 30000000) {
                alert(`${file.name} は容量が大きすぎるためアップロードできません。ファイル容量の上限は30MBです。`);
                this.$emit('setProcessing',false);
                return;
              }
              // 変更中フラグfalse
              this.nowChangeFlag = false;
              // プレビューが表示されるまでフラグを立てる
              this.nowRenderingFlag = true;
              // プレビュー画像アップしてゲットしてセットする
              this.uploadTmp(file);
            },
            // プレビュー用に画像をアップロード
            uploadTmp(file) {
                // フォームで送信するFormDataインスタンスつくる
                const formData = new FormData();
                // 送信するフィールドのデータを登録する
                formData.append('field', this.name);
                // アップロードするファイルを登録する
                formData.append('images[0]', file, file.name);
                // アップロード
                axios.post('/tmp-upload',formData)
                .then((res)=>{
                  var tmpFile = res.data.uploadFiless[0];
                  // プレビュー用のURLをデータにほりこむ
                  this.previewFile = {
                    src: '/storage/images/tmp/prv/'+tmpFile.filename,
                    file: {
                      name: file.name,
                      size: file.size,
                    }
                  }
                  // いっこ上の階層のVueコンポーネントのメソッド（ファイルをセット）にファイル（tmpファイルのファイル名）を投げる
                  this.$emit('setFile',tmpFile.filename);
                  // データの変更後に Vue.js の DOM 更新の完了を待つには、データが変更された直後に Vue.nextTick(callback) を使用することができます。そのコールバックは DOM が更新された後に呼ばれます。
                  this.$nextTick(function () {
                    // DOMの更新が終わったら
                    // プレビュー表示処理中フラグをfalse
                    this.nowRenderingFlag = false;
                    // アップローダー非表示
                    this.uploaderShowFlag = false;
                    this.$emit('setProcessing',false);
                  })
                })
                .catch((error) => {
                  // console.log(error.response);
                  // バリデーションエラーの場合、this.errorsにエラー内容をほりこむ（=エラー表示される）
                  if(error.response.data.errors) {
                    var errors = {};
                    for(var key in error.response.data.errors) {
                      errors[key] = error.response.data.errors[key].join('<br>');
                    }
                    this.errors = errors;
                  } else {
                    // バリデーションエラーの場合以外はエラーダイアログを表示
                    // エラーデータをほりこむ
                    this.error = error.response;
                    this.showErrorDialog = true;
                    this.$emit('setProcessing',false);
                  }
                })
            },
            // 現在有効な（=プレビュー表示中の）画像を消去
            remove() {
              // もともと登録してあったファイルがあれば
              if(this.currentFile) {
                // 元画像の情報を消す関連の処理
                this.remove_current();
              } else { // もともと登録してあったファイルがなければ
                // プレビュー用のURLを消す
                this.file_preview_src = null;
                // 登録用のファイルオブジェクトを消す
                this.file = null;
                // // アップローダーを隠す
                // this.uploaderShowFlag = false;
              }
              // アップローダーを表示
              this.uploaderShowFlag = true;
              // いっこ上の階層のVueコンポーネントのメソッド（ファイルをセット）にnullを投げる（そっちの方でも削除の処理する）
              this.$emit('setFile',null);
            },
            // 元画像の情報を消す関連の処理
            remove_current() {
              // // 編集前の状態に戻す機能が必要になった時用に、消した元画像のファイル名を入れておく
              // if(!this.currentFileRemoved) this.currentFileRemoved = this.currentFile;
              // 元画像の情報を消す
              this.currentFile = null;
            },
        }
    }
</script>

<style lang="scss" scoped>
  .uploader {
    width: 100%;
    height: 200px;
    margin: 0;
    padding: 2rem 1.5rem;
    text-align: center;
    border: 3px dashed #CCC;
    position: relative;
    background-color: #eee;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    &.dragging {
      background-color: rgba(0,120,230,.5);
      * {
        opacity: .5;
      }
    }
    &> .inner {
      margin: 0 auto;
    }
    .message {
      font-size: 1.7rem;
      font-weight: bold;
      margin: 0;
      line-height: 1;
      span {
        display: block;
        margin-top: -.3rem;
        font-size: 1.3rem;
        font-weight: normal;
      }
    }
    .file-input {
      // width: 100%;
      // text-align: center;
      // margin: auto;
      position: relative;
      input {
        position: absolute;
        left: 0;
        top: 0;
        width: 0;
        height: 0;
        opacity: 0;
      }
    }
    .details {
      margin-top: .7rem;
      span {
        margin: 0 .7rem;
      }
    }
  }
</style>
