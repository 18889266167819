<template>
  <form method="POST" :action="actionurl" class="register_form pure-form pure-form-stacked" enctype="multipart/form-data" @submit.prevent>

        <div class="invalid-feedback" role="alert" v-html="errors.self_introduction"></div>
                              <!-- <li v-for="(value, key, index) in validationErrors()">@{{ value }}</li> -->

        <fieldset class="">

          <div class="form-group required">
            <div class="form_header">
              <span class="label">写真</span>
            </div>
            <div class="form_body">
              <div class="form_input">
                <image-uploader title="写真ファイル" name="pg" :current="node.file_name" :prvpath="'/storage/images/pg/sample/'"
                @setFile="setFile"
                @setDateTime="setDateTime"
                @setProcessing="setProcessing"
                @setImageRotate="setImageRotate">
                </image-uploader>
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.file"></div>
                <div class="note">30MBまでのJPEG形式の画像ファイル</div>
              </div>
            </div>
          </div>

          <div class="form-group required">
            <div class="form_header">
              <span class="label">タイトル</span>
            </div>
            <div class="form_body">
              <div class="form_input">
                <input id="title" type="text" class="pure-input-1" :class="{ 'is-invalid':errors.title }" name="title" v-model="node.title" required autocomplete="title" autofocus>
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.title"></div>
                <div class="note">この作品のタイトルを入力してください。できるだけ他と重複しないようにしてください。</div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="form_header">
              <span class="label">キャプション（説明文、コメント）</span>
            </div>
            <div class="form_body">
              <div class="form_input">
                <!-- <textarea id="caption" :class="{ 'is-invalid':errors.caption }" name="caption" rows="6" v-model="node.caption"></textarea> -->
                <textarea-auto-adjust
                  :errors="errors.caption"
                  name="caption"
                  v-model="node.caption"
                ></textarea-auto-adjust>
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.caption"></div>
                <div class="note">この作品のキャプション（説明文）を入力してください。（最大500文字）</div>
              </div>
            </div>
          </div>

        </fieldset>

        <fieldset class="">

          <div class="form-group">
            <div class="form_header">
              <span class="label">有料販売</span>
            </div>
            <div class="form_body">
              <div class="form_input">
                <div class="form_inputs">
                  <label for="not_for_sale" class="single_checkbox">
                    <input id="not_for_sale" type="checkbox" class="" name="not_for_sale" value="1" autocomplete="not_for_sale" true-value="1" false-value="0" v-model="node.not_for_sale"> この写真の有料販売を不可にする
                  </label>
                </div>
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.not_for_sale"></div>
                <div class="note">作品販売登録をされている（またはその予定の）フォトグラファー様で、この作品を販売不可に設定したい場合はチェックしてください。</div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="form_header">
              <span class="label">モデルリリース</span>
            </div>
            <div class="form_body">
              <div class="form_input">
                <div class="form_inputs">
                  <label for="model_release" class="single_checkbox">
                    <input id="model_release" type="checkbox" class="" name="model_release" value="1" autocomplete="model_release" true-value="1" false-value="0" v-model="node.model_release"> モデルリリース取得済
                  </label>
                </div>
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.model_release"></div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="form_header">
              <span class="label">プロパティリリース</span>
            </div>
            <div class="form_body">
              <div class="form_input">
                <div class="form_inputs">
                  <label for="property_release" class="single_checkbox">
                    <input id="property_release" type="checkbox" class="" name="property_release" value="1" autocomplete="property_release" true-value="1" false-value="0" v-model="node.property_release"> プロパティリリース取得済
                  </label>
                </div>
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.property_release"></div>
              </div>
            </div>
          </div>

        </fieldset>


        <fieldset class="">

          <div class="form-group required">
            <div class="form_header">
              <span class="label">プレイス（撮影場所）</span>
            </div>
            <div class="form_body">
              <div class="form_input">
                <tag-editor :tagColumn="'place'" :class="{ 'is-invalid':errors.places }" :currentTags="node.places" @setTags="setPlaces"></tag-editor>
              </div>
              <div v-if="node.places">
                <div v-for="result in matchPlaceResults" :key="result.key">
                  <div v-if="result.spots">
                    <div class="place">「{{ result.place }}」で
                      <a v-for="spot in result.spots" :key="spot.id" :href="'/spot/' + spot.id" target="_blank">{{ spot.name }}</a> と関連付けられます。
                    </div>
                  </div>
                  <div v-else>
                    <div class="place">「{{ result.place }}」でマッチするスポットが今の所登録されていません。</div>
                  </div>
                </div>
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.places"></div>
                <div class="note">撮影場所の名前を入力してください。ここに入力された名前は当サイトに掲載のスポットと照合され、自動的に関連づけられます（なるべく一般的と思われる名前を推奨します。）。複数ある場合はカンマ区切りで入力してください。「◯◯寺の門の前」のように詳細な情報を含めたい場合は、別途「◯◯寺」単体でも入力してください。</div>
                <div class="note">例: <br>金閣寺の場合→「金閣寺, 鹿苑寺, 鹿苑寺金閣」<br>金戒光明寺の場合→「金戒光明寺, 黒谷さん, 黒谷, くろ谷さん, くろ谷」など</div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="form_header">
              <span class="label">タグ</span>
            </div>
            <div class="form_body">
              <div class="form_input">
                <tag-editor :tagColumn="'tag'" :currentTags="node.tags" @setTags="setTags"></tag-editor>
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.tags"></div>
                <div class="note">この作品に関連するワードをタグとして入力します。同じタグがついた他の作品やその他のページと関連づけられます。カンマ区切りで入力してください。</div>
              </div>
            </div>
          </div>


          <div class="form-group required">
            <div class="form_header">
              <span class="label">カテゴリー</span>
            </div>
            <div class="form_body">
              <div class="form_input">
                <category-checker :entity="'photo'" :currentCategories="node.categories" @setCategories="setCategories"></category-checker>
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.categories"></div>
                <div class="note">この作品が該当するカテゴリーを全てチェックしてください。</div>
              </div>
            </div>
          </div>

        </fieldset>


        <fieldset class="" v-if="isStaff">
          <div class="form-group">
            <div class="form_header">
              <span class="label">公開状態</span>
            </div>
            <div class="form_body">
              <div class="form_input">
                <status-checker :config_status="config_status" :enable_values="enable_status" :currentStatus="node.status ? node.status : 40" @setStatus="setStatus"></status-checker>
              </div>
              <div class="form_note">
                <div class="note">公開状態を選択してください</div>
              </div>
            </div>
          </div>
        </fieldset>

        <!-- {{ user.roles }} -->
        <!-- {{ user.roles.some(role => role.role <= 20) }} -->

        <div class="form_buttons">
          <a v-if="nodeprop" v-bind:disabled="isProcessing" class="btn cancelBtn" v-bind:href="'/photo/' + nodeprop.id">戻る</a>
          <button v-if="nodeprop" v-bind:disabled="isProcessing" type="button" class="btn btn-primary" @click.prevent.stop="onSubmit()">更新</button>
          <button v-else type="button" v-bind:disabled="isProcessing" class="btn btn-primary" @click.prevent.stop="onSubmit()">投稿</button>
          <button v-if="nodeprop && user.roles && user.roles.some(role => role.role <= 20)" v-bind:disabled="isProcessing" type="button" class="btn btn-warning" @click="deleteNode">削除</button>
        </div>

        <div class="dialog dialog-t" v-if="showProgress">
          <div class="inner">
            <div class="dialog-header">
              {{dialogTitle}}
            </div>
            <div class="dialog-content">
              <div class="dialog-loading">
                <span class="loader icon">Loading...</span>
                <span>{{dialogMessage}}</span>
              </div>
              <!--
              <div v-if="progress != 100">
                ファイルをアップロード中... {{progress}}%
                <progress ref="progress" value="0" max="100"></progress>
              </div>
              <div v-if="progress == 100" class="icon-inline">
              </div>
              -->
            </div>
          </div>
        </div>

        <div class="dialog dialog-t" v-if="showValidationErrorDialog">
          <div class="inner">
            <div class="dialog-header">
              入力内容をご確認ください
            </div>
            <div class="dialog-content">
              <ul v-for="error in validationErrors" :key="error.id">
                <li v-for="message in error.messages" :key="message.id">{{ message.message }}</li>
              </ul>
              <div class="buttons">
                <button type="button" class="btn btn-primary" @click="showValidationErrorDialog = false">OK</button>
              </div>
            </div>
          </div>
        </div>

        <div class="dialog dialog-t" v-if="showErrorDialog">
          <div class="inner">
            <div class="dialog-header">
              {{error.status}} {{error.statusText}}
            </div>
            <div class="dialog-content">
              <div>{{error.data.message}}</div>
              <div class="buttons">
                <button type="button" class="btn btn-primary" @click="showErrorDialog = false">OK</button>
              </div>
            </div>
          </div>
        </div>

  </form>

</template>

<script>
    import ImageUploader from './ImageUploader'
    import ImageUploaderPop from './ImageUploaderPop'
    import TagEditor from './TagEditor';
    import CategoryChecker from './CategoryChecker';
    import TextareaAutoAdjust from './form_element/TextareaAutoAdjust';
    import StatusChecker from './form_element/StatusChecker';
    export default {
        components: {
            ImageUploader,
            TagEditor,
            CategoryChecker,
            TextareaAutoAdjust,
            StatusChecker,
        },
        props: {
          // nodeプロパティ
            nodeprop: {
                type: Object,
                default: null,
                required: false,
            },
            // フォームの送信先
            actionurl: {
                type: String,
                required: true,
            },
            user: {
                type: Object,
                required: true,
            },
            config_status: {
                type: Object,
                required: true,
            },
        },
        data: () => ({
            isAdmin: false,
            isStaff: false,
            // post
            node: {
              file_name: '',
              title: '',
              caption: '',
              date_time: null,
              not_for_sale: 0,
              model_release: 0,
              property_release: 0,
              tagsp: [],
              places: [],
            },
            enable_status: [ // {1: "公開", 5: "限定公開", 10: "予約", 20: "下書き", 40: "承認申請中", 45: "承認却下", 60: "公開制限中", 90: "非公開"}
              // 1, 5, 10, 20, 40, 45, 60, 90,
              1, 40, 45, 60, 90,
            ],
            // 写真の登録用画像
            file: null,
            // 画像の回転情報（0〜3）
            imageRotate: 0,
            // // 写真のプレビュー用画像
            // file_image_preview: null,
            // 元の写真を削除したらtrue
            remove_file: false,
            // バリデーションエラーダイアログ表示フラグ
            showValidationErrorDialog: false,
            // バリデーションエラーダイアログ表示フラグ
            validationErrors: [],
            // エラーダイアログ表示フラグ
            showErrorDialog: false,
            // 返ってきたerror.responceが入る
            error: {},
            // バリデーションエラーの内容が入る
            errors: {},
            // プログレスダイアログ用の進捗状況（%）
            progress: 0,
            // プログレスタイトル
            dialogTitle: '投稿処理',
            // プログレスメッセージ
            dialogMessage: 'データを処理中。完了後は自動的にページに移動します。',
            // プログレスダイアログ表示フラグ
            showProgress: false,
            // プレイスでマッチしたプレイスデータを入れる
            findPlaceResults: [],
            // matchPlaceResults: [],
            // 処理中はtrueにするとボタンが無効になる
            isProcessing: false,
        }),
        created() {
            // console.log('this.user');
            // console.log(this.user);
            this.isAdmin = this.user.roles.some(user => user.role === 1);
            this.isStaff = this.user.roles.some(user => user.role === 1 || user.role === 10 || user.role === 20);
            // console.log(this.nodeprop);
            if(this.nodeprop) {
              // ユーザープロパティをdataにセット
              this.node = this.nodeprop;
              // プレイスの関連付け情報を取得
              if(this.nodeprop.places) {
                this.findPlaces();
              }
            }
            // if(this.nodeprop.categories) {
            //   this.node.categories = this.nodeprop.categories.map(category => {
            //     return category.id;
            //   });
            // }
            // console.log(this.node);

            // this.set_cover_image_preview_style();
        },
        computed: {
          // プレイスの入力値にマッチするスポットの情報を返す
          matchPlaceResults() {
            if(this.node.places) {
              return this.node.places.map((val, key) => {
                var match = null;
                if(this.findPlaceResults.length >= 1) {
                  match = this.findPlaceResults.find(v => v.place == val.place);
                }
                if(match && match.spots.length >= 1) {
                  return {key: key, place: val.place, spots: match.spots}
                } else {
                  return {key: key, place: val.place, spots: null}
                }
              });
            }
          },
        },
        methods: {
            setStatus(status) {
              this.node.status = status;
              // console.log(this.node.status);
            },
            // ファイルを追加（アップローダーコンポーネントで画像追加したらこれが発火 ※引数にファイルが入ってくる）
            // または ファイルを削除（アップローダーコンポーネントで画像削除したらこれが発火 ※引数はnull）

            setProcessing(status) {
              this.isProcessing = status;
              console.log(this.isProcessing);
            },
            setFile(file) {
              console.log('setFile');
                if(file) { // ファイルが送られてきたら（=画像が追加された）
                  // 登録用画像
                  this.file = file;
                } else { // 引数がnullだったら（=画像が削除された）
                  // 登録用画像を削除
                  this.file = null;
                }
                if(this.node.file_name) {
                  // nodeのfileを削除
                  this.node.file_name = null;
                  // 古いの消したよフラグ立てる
                  this.remove_file = true;
                }
              console.log('setFile2');
            },
            setImageRotate(imageRotate) {
                // console.log(imageRotate);
                this.imageRotate = imageRotate;
            },
            setDateTime(date_time) {
                // console.log(date_time);
                this.node.date_time = date_time;
                // console.log('セット:'+this.node.date_time);
            },
            onUpload(e) {
              if(this.showProgress == true) {
                // console.log(e.loaded +'/'+ e.total);
                // alert(e.loaded +'/'+ e.total);
                // プログレスバーを計算して変更
                this.$refs.progress.value = Math.floor((e.loaded * 100) / e.total);
                this.progress = Math.floor((e.loaded * 100) / e.total);
              }
            },
            // タグをセット
            setTags(tagsArr) {
              // console.log(tagsArr);
              var newTags = [];
              tagsArr.forEach(tag =>{
                if(tag) {
                  newTags.push({
                    tag: tag,
                  });
                }
              });
              if(JSON.stringify(this.node.tags) != JSON.stringify(newTags)) { //前後の変数を比較して異なっていた時だけ処理する
                this.node.tags = newTags;
              }
            },
            // プレイスをセット
            setPlaces(placesArr) {
              // その都度やってたら1文字ごとにクエリが飛ぶので、1秒待ってから処理。その間に次のやつ来たら前のをキャンセルする。
              if(this.setPlacesQue) {
                clearTimeout(this.setPlacesQue);
              }
              this.setPlacesQue = setTimeout(this.executeSetPlaces, 1000, placesArr);
            },
            executeSetPlaces(placesArr) {
              var newPlaces = [];
              placesArr.forEach(place =>{
                if(place) {
                  newPlaces.push({
                    place: place,
                  });
                }
              });
              if(JSON.stringify(this.node.places) != JSON.stringify(newPlaces)) { //前後の変数を比較して異なっていた時だけ処理する
                this.node.places = newPlaces;
                this.findPlaces();
              }
            },
            findPlaces() {
              // プレイス名の配列を作る
              var placeNames = this.node.places.map(v => { return v.place });
              // マッチするスポットを探す
              axios.post('/place/find_spot', { places: placeNames }).then((res)=>{
                if(res.data) {
                  // プレイスがあったらデータに反映
                  this.findPlaceResults = res.data;
                  // console.log('res.data');
                  // console.log(res.data);
                }
                // console.log(this.node.places);
                // console.log(this.matchPlaceResults);
              })
              // ToDo: エラー処理書く
              .catch(error => console.log(error))
            },
            // カテゴリーをセット
            setCategories(categoriesArr) {
              var newCategories = categoriesArr.map(category =>{
                return {id: category};
              });
              this.node.categories = newCategories;
                // console.log('かてごり:'+this.node.dete_time);
            },
            // echoErrors() {
            //   this.showValidationErrorDialog = true;
            // },
            deleteNode() {
              this.isProcessing = true;
              this.dialogTitle = "削除処理";
              this.dialogMessage = "削除処理中。このままお待ち下さい。";
              this.showProgress = true;
              const formData = new FormData();
              // formData.append('_method', 'DELETE');
              // 削除リクエスト投げる
              axios.delete('/photo/' + this.node.id)
              .then( response => {
                // // プログレスダイアログを消す
                // this.showProgress = false;
                // // プログレスの値をリセット
                // this.progress = 0;
                // console.log('response↓');
                // console.log(response.data);
                // リダイレクトURLが返ってきたら（=正常に登録できた）ページ移動する（シングルページにリダイレクト）
                if(response.data.redirect_path) {
                  document.location.href = response.data.redirect_path;
                  // if(confirm(response.data.message + 'ページに移動しますか？')) document.location.href = response.data.redirect_path;
                } else {
                  this.isProcessing = false;
                  // console.log(response);
                  // console.log('test');
                  // console.log('response');
                  // console.log(response);
                  // console.log('response');
                  // console.log(response.data.redirect_path);
                  // console.log('node↓');
                  // console.log(response.data.node);
                  // プログレスダイアログを消す
                  this.showProgress = false;
                  // プログレスの値をリセット
                  this.progress = 0;
                  alert('例外が発生しました。Error: PhotoEdit001');
                }
              }).catch(
                (error) => {
                  this.isProcessing = false;
                  this.showProgress = false;
                  // 例外処理
                  // console.log(error);
                  // console.log(error.response.status);
                  // console.log(error.response);
                  // this.success = false;
                  // バリデーションエラーの場合、this.errorsにエラー内容をほりこむ（=エラー表示される）
                  if(error.response.data.errors) {
                    var errors = {};
                    for(var key in error.response.data.errors) {
                      errors[key] = error.response.data.errors[key].join('<br>');
                    }
                    this.errors = errors;
                    // console.log(error.response.data.errors);

                    var validationErrors = [];
                    for(var key in error.response.data.errors) {
                      validationErrors.push({
                        id: key,
                        messages: error.response.data.errors[key].map((message, key) => {
                            return {
                              id: key,
                              message: message,
                            };
                          }),
                      });
                      this.validationErrors = validationErrors;
                    }
                    // console.log(this.validtionErrors);
                    // this.echoErrors();
                    this.showValidationErrorDialog = true;

                  } else {
                    // バリデーションエラーの場合以外はエラーダイアログを表示
                    // エラーデータをほりこむ
                    this.error = error.response;
                    this.showErrorDialog = true;
                  }
                }
              );
            },
            // サブミット！
            onSubmit() {
              // console.log('submit!');
              // alert('送信');
                this.isProcessing = true;
                this.dialogTitle = "投稿処理";
                this.dialogMessage = "データを処理中。完了後は自動的にページに移動します。";
                this.showProgress = true;
                // フォームで送信するFormDataインスタンスつくる
                const formData = new FormData();
                // 送信するフィールドのデータを登録する
                // propで投稿情報が来てたら（updateだったら）
                if(this.nodeprop) {
                  // PUTで送ってるように偽装する（普通にPUTで送れないため）
                  formData.append('_method', 'PUT');
                }
                // 元の写真を削除した場合にtrueを送る（コントローラーでファイルを消すため）
                if(this.remove_file) formData.append('remove_file', true);
                // console.log(this.file);
                // 登録する写真を登録
                if(this.file) {
                  formData.append('file', this.file);
                  formData.append('date_time', (this.node.date_time === "undefined" || this.node.date_time === null) ? "" :this.node.date_time);
                }
                // 写真の回転情報を登録
                if(this.imageRotate.rotate) formData.append('imageRotate', this.imageRotate.rotate);
                // console.log(this.imageRotate.rotate);
                // console.log(this.file);
                // return false;
                // すでに写真があって更新しない場合file_noupd=true をセットする（バリデーションで使うため）
                if(this.node.file_name && !this.file && !this.remove_file) formData.append('file_noupd', true);
                // その他のデータを登録
                formData.append('title', this.node.title);
                formData.append('caption', this.node.caption);
                formData.append('not_for_sale', this.node.not_for_sale);
                formData.append('model_release', this.node.model_release);
                formData.append('property_release', this.node.property_release);
                if(this.node.status != null) formData.append('status', this.node.status);
                // タグをセット
                if(this.node.tags) {
                  this.node.tags.forEach(tag => {
                    formData.append('tags[]', tag.tag);
                  });
                }
                // プレイスをセット
                if(this.node.places) {
                  this.node.places.forEach(place => {
                    formData.append('places[]', place.place);
                  });
                }
                // カテゴリーをセット
                if(this.node.categories) {
                  this.node.categories.forEach(category => {
                    formData.append('categories[]', category.id);
                  });
                }
                // 写真を追加または変更した場合（=ファイルのアップロードと生成処理が必要=>時間がかかる）プログレスダイアログを表示
                if(this.file || false) {
                  this.showProgress = true;
                }
                // //config定義（headersを指定）
                // const config = {headers:{
                //   'Content-Type': 'multipart/form-data',
                //   'onUploadProgress': this.onUpload,
                // }}
                // //axiosでPOST送信
                // axios.post(this.actionurl, formData, config)
                //axiosでPOST送信
                axios.post(this.actionurl, formData, {
                  onUploadProgress: this.onUpload,
                  headers: {
                    'content-type': 'multipart/form-data'
                  }
                })
                .then( response => {
                  // alert('submit4');
                  // // プログレスダイアログを消す
                  // this.showProgress = false;
                  // // プログレスの値をリセット
                  // this.progress = 0;
                  // console.log('response↓');
                  // console.log(response.data);
                  // リダイレクトURLが返ってきたら（=正常に登録できた）ページ移動する（シングルページにリダイレクト）
                  if(response.data.redirect_path) {
                    document.location.href = response.data.redirect_path;
                    // if(confirm(response.data.message + 'ページに移動しますか？')) document.location.href = response.data.redirect_path;
                  } else {
                    this.isProcessing = false;
                    // console.log(response);
                    // console.log('test');
                    // console.log('response');
                    // console.log(response);
                    // console.log('response');
                    // console.log(response.data.redirect_path);
                    // console.log('node↓');
                    // console.log(response.data.node);
                    // プログレスダイアログを消す
                    this.showProgress = false;
                    // プログレスの値をリセット
                    this.progress = 0;
                    alert('例外が発生しました。Error: PhotoEdit001');
                  }
                }).catch(
                  (error) => {
                    this.isProcessing = false;
                    this.showProgress = false;
                    // 例外処理
                    // console.log(error);
                    // console.log(error.response.status);
                    // console.log(error.response);
                    // this.success = false;
                    // バリデーションエラーの場合、this.errorsにエラー内容をほりこむ（=エラー表示される）
                    if(error.response.data.errors) {
                      var errors = {};
                      for(var key in error.response.data.errors) {
                        errors[key] = error.response.data.errors[key].join('<br>');
                      }
                      this.errors = errors;
                      // console.log(error.response.data.errors);

                      var validationErrors = [];
                      for(var key in error.response.data.errors) {
                        validationErrors.push({
                          id: key,
                          messages: error.response.data.errors[key].map((message, key) => {
                              return {
                                id: key,
                                message: message,
                              };
                            }),
                        });
                        this.validationErrors = validationErrors;
                      }
                      // console.log(this.validtionErrors);
                      // this.echoErrors();
                      this.showValidationErrorDialog = true;

                    } else {
                      // バリデーションエラーの場合以外はエラーダイアログを表示
                      // エラーデータをほりこむ
                      this.error = error.response;
                      this.showErrorDialog = true;
                    }
                    this.isProcessing = false;
                  }
                );
            },
        }
    }
</script>
